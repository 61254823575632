import React from "react";
import { navigate } from "gatsby";

import { useIsLargeMobileAndBelow } from "hooks/breakpoints";

import Main from "components/layout/Main";
import MediaSection from "components/MediaSection";
import Heading from "components/Heading";
import Paragraph from "components/Paragraph";
import Button from "components/Button";
import HeaderSkew from "components/HeaderSkew";
import HeaderSkewVideo from "components/HeaderSkewVideo";
import Block from "components/Block";
import Picture from "components/Picture";
import ContentGrid from "components/ContentGrid";
import MediaContainer from "components/MediaContainer";
import MediaSectionSlim from "components/MediaSectionSlim";
import ToolsCollage from "components/ToolsCollage";
import ParagraphPointer from "components/ParagraphPointer";

import PictureAsset1 from "assets/images/watch_video.png";
import PictureAsset1Webp from "assets/images/watch_video.webp";
import PictureAsset2 from "assets/images/home/new/graphic.jpg";
import PictureAsset2Webp from "assets/images/home/new/graphic.webp";
import PictureAsset5 from "assets/images/home/helping_communities.jpg";
import PictureAsset5Webp from "assets/images/home/helping_communities.webp";
import PictureAsset6 from "assets/images/home/new/conscious.jpg";
import PictureAsset6Webp from "assets/images/home/new/conscious.webp";
import PictureAsset7 from "assets/images/home/phone_black.png";
import PictureAsset7Webp from "assets/images/home/phone_black.webp";
import AwardsBannerColor from "assets/images/home/awards_banner_color_2021.png";
import WrapperModalVideo from "components/WrapperModalVideo.js";
import WlLogo from "assets/images/wol/warrior-logo.svg";
import WoL1 from "assets/images/wol/wolchapter2.png";
import WoL1Webp from "assets/images/wol/wolchapter2.webp";
import WoL2 from "assets/images/wol/wolchapter3.png";
import WoL2Webp from "assets/images/wol/wolchapter3.webp";
import HomePartners from "assets/images/home/partners.png";
import HomePartnersW from "assets/images/home/partners.webp";
import WrapperContent from "components/WrapperContent";

import ScrollToTop from "components/ScrollToTop";

import HeaderVideoDesktop from "assets/images/header_skew_video/tami_day.mp4";
import HeaderVideoMobile from "assets/images/header_skew_video/tami_day_mobile.mp4";

import HeaderVideoPosterDesktop from "assets/images/header_skew_video/tami_day.jpg";
import HeaderVideoPosterMobile from "assets/images/header_skew_video/tami_day_mobile.png";

function offsetImage() {
  return (
    <Picture
      src={PictureAsset7}
      webp={PictureAsset7Webp}
      type="clickable"
      alt="Know your loan. Love your lender."
      className="offset-img-home"
    />
  );
}

export default function IndexPage() {
  const TagOffsetImage = offsetImage;

  const isMobile = useIsLargeMobileAndBelow();

  return (
    <Main page={"home"} pagetitle="Home">
      <div className="mobile-only">
        <HeaderSkewVideo
          background={HeaderVideoDesktop}
          offsetImage={TagOffsetImage}
          video={isMobile ? HeaderVideoMobile : null}
          poster={HeaderVideoPosterMobile}
        />
      </div>
      <div className="desktop-only">
        <HeaderSkewVideo
          background={HeaderVideoDesktop}
          offsetImage={TagOffsetImage}
          video={!isMobile ? HeaderVideoDesktop : null}
          poster={HeaderVideoPosterDesktop}
        />
      </div>
      <Block layer="1" name="" guides={2} appearance="grid-top--home" />
      <div className="pointer-parent">
        <ParagraphPointer linkedTo="#p1" />
        <ParagraphPointer linkedTo="#p2" />
        <ParagraphPointer linkedTo="#p3" />
        <ParagraphPointer linkedTo="#p4" />

        <Block layer="1" name="" guides={2} appearance="grid-top-min" />
        {/**/}

        <Block layer="1" name="About Us" guides={2} appearance="grid-home">
          <ContentGrid appearance="main">
            <div id="p1">
              <div data-aos="fade-up">
                <Heading type="h2" appearance="h1--dark">
                  America’s #1 point-of-sale platform for sustainable home
                  solutions
                </Heading>

                <Paragraph appearance="dark">
                  We partner with sustainable home improvement professionals
                  across the country to make sustainable home upgrades simple,
                  with flexible payment options that fit any budget.
                </Paragraph>
                <WrapperContent appearance="buttons">
                  <Button
                    handleClick={() => navigate("/partners/")}
                    appearance="slim-ghost"
                    aria-label="Go to Partners"
                    caret
                  >
                    For Partners
                  </Button>
                  <Button
                    handleClick={() => navigate("/homeowners/")}
                    appearance="slim-ghost"
                    aria-label="Go to Homeowners"
                    caret
                  >
                    For Homeowners
                  </Button>
                </WrapperContent>
              </div>
            </div>

            <div />

            <div>
              <MediaContainer appearance="offset-1">
                <Picture
                  src={PictureAsset2}
                  webp={PictureAsset2Webp}
                  width="630"
                  height="380"
                  alt="Our experience stands out."
                />
              </MediaContainer>
            </div>

            <div id="p2">
              <div data-aos="fade-up">
                <Heading type="h2" appearance="h1--dark">
                  A smarter way to pay (and save)
                </Heading>

                <Paragraph appearance="dark">
                  For countless homeowners across the country, we’ve engineered
                  a smarter way to pay for modern home upgrades, removing
                  barriers to purchase with favorable options that save them
                  money. This is how dreamers become doers.
                </Paragraph>
              </div>
            </div>

            <MediaContainer appearance="offset-1">
              <WrapperModalVideo videoId="pnaWJKtQIi4">
                <Picture
                  src={PictureAsset1}
                  webp={PictureAsset1Webp}
                  type="clickable"
                  width="630"
                  height="380"
                  alt="Know your loan. Love your lender."
                />
              </WrapperModalVideo>
            </MediaContainer>

            <div id="p3">
              <div data-aos="fade-up">
                <Heading type="h2" appearance="h1--dark">
                  We are world positive
                </Heading>

                <Paragraph appearance="dark">
                  We are dedicated to the cause of caring for the planet by
                  helping people convert to a sustainable lifestyle.
                </Paragraph>
              </div>
            </div>
          </ContentGrid>
        </Block>
        {/* */}
        <Block layer="1" name="Direct Pay" guides={1} appearance="home-orange">
          <ContentGrid appearance="centered">
            <>
              <Heading type="h2" appearance="h3--white">
                Flexible, friendly payment options homeowners love
              </Heading>
              <Paragraph appearance="white">
                Now, over 12,000 sustainable home improvement professionals are
                on Loanpal’s industry-leading platform. Homeowners everywhere
                enjoy the ease and flexibility of Loanpal’s payment options
                while maximizing savings.
              </Paragraph>

              <WrapperContent appearance="centered-left">
                <div className="btns-row">
                  <Button
                    handleClick={() => navigate("/partners/")}
                    appearance="slim-ghost-white"
                    aria-label="Go to Partners"
                    caret
                  >
                    For Partners
                  </Button>
                  <Button
                    handleClick={() => navigate("/homeowners/")}
                    appearance="slim-ghost-white"
                    aria-label="Go to Homeowners"
                    caret
                  >
                    For Homeowners
                  </Button>
                </div>

                <MediaContainer modifier={["custom-1"]}>
                  <Picture
                    src={HomePartners}
                    webp={HomePartnersW}
                    className="home-partners"
                    alt="We are mission-driven"
                  />
                </MediaContainer>
              </WrapperContent>
            </>
          </ContentGrid>
        </Block>
        <Block
          layer="1"
          name="Direct Pay"
          guides={1}
          appearance="sustainable-bottom"
        ></Block>
        <Block
          layer="1"
          name="About our Tools"
          guides="1"
          appearance="grid-tools-gray"
        >
          <ContentGrid appearance="tools">
            <div id="p4">
              <div data-aos="fade-up">
                <Heading type="h2" appearance="h1--dark">
                  Tools that provide the fastest, smoothest way to help
                  homeowners upgrade their homes sustainably
                </Heading>

                <Paragraph appearance="dark">
                  Loanpal Pros is a powerful tool that drives the payment
                  application process from beginning to end, all from the
                  convenience of a mobile device. Instantly compare rates,
                  submit documents, and get approved in seconds.
                </Paragraph>
              </div>
            </div>
            <div />
            <ToolsCollage data-aos="fade-in" />
          </ContentGrid>

          <MediaSectionSlim
            description="On the frontlines of the solar revolution are some of the most driven individuals, dedicated to the cause of caring for the planet by helping people convert to a sustainable lifestyle. These are the warriors Loanpal seeks to spotlight in this video series."
            modifier={["beta", "white", "top", "gama"]}
            appearance="warriors"
            button="/warriors-of-light"
            buttonText="Watch videos"
          >
            <MediaContainer
              modifier={["full-center", "no-shadow", "borderless"]}
            >
              <Picture
                src={WlLogo}
                width="446"
                height="295"
                alt="Warriors of Light"
                type="clickable"
                className="warriors-of-light-img"
              />
            </MediaContainer>

            <MediaContainer modifier={["half"]}>
              <Picture
                src={WoL1}
                webp={WoL1Webp}
                width="625"
                height="410"
                alt="On the frontlines of the solar revolution are some of the most driven individuals, dedicated to the cause of caring for the planet by helping people convert to a sustainable lifestyle. These are the warriors Loanpal seeks to spotlight in this video series."
                type="clickable"
              />
            </MediaContainer>
            <MediaContainer modifier={["half"]}>
              <Picture
                src={WoL2}
                webp={WoL2Webp}
                width="625"
                height="410"
                alt="We are the official sponsor of GivePower Foundation."
              />
            </MediaContainer>
          </MediaSectionSlim>

          <MediaSectionSlim
            title="Purpose & Impact"
            description="Together, with the GivePower Foundation, we are the Conscious Collective. As we continue to push the clean energy revolution forward, we remain focused on creating meaningful impact in the developing world. We make a donation with every transaction on the Loanpal platform to cover 100% of GivePower’s operational overhead and provide 20 years of access to clean drinking water for thousands in need."
            modifier={["omega", "delta"]}
          >
            <MediaContainer>
              <WrapperModalVideo videoId="qV72tuG5aA4">
                <Picture
                  src={PictureAsset6}
                  webp={PictureAsset6Webp}
                  width="456"
                  height="300"
                  alt="A donation with every home loan we fund can provide someone in need with access to clean drinking water for 20 years."
                  type="clickable"
                />
              </WrapperModalVideo>
            </MediaContainer>
            <MediaContainer>
              <Picture
                src={PictureAsset5}
                webp={PictureAsset5Webp}
                width="456"
                height="300"
                alt="We are the official sponsor of GivePower Foundation."
              />
            </MediaContainer>
          </MediaSectionSlim>
        </Block>

        <Block layer="2" name="Media Section Grey" appearance="grid-top-medium">
          <MediaSection appearance="grey-slim">
            <Picture src={AwardsBannerColor} alt="Awards Banner" />
          </MediaSection>
        </Block>
        <ScrollToTop modifier={["gray", "slim-top"]} guides={0} />
      </div>
      <></>
    </Main>
  );
}
