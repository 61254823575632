import React from "react";
import "./MediaSection.scss";

export default function MediaSection({
  title = "",
  description = "",
  children,
  appearance = "orange",
}) {
  return (
    <div className={`media-section media-section--${appearance}`}>
      <div className="media-section__container" data-aos="fade-in">
        {title && <h2 className="media-section__title">{title}</h2>}
        {description && <p className="media-section__text">{description}</p>}
        <div className="media-section__children">{children}</div>
      </div>
      <div className="media-section__background">
        <div className="media-section__background_overlay" />
      </div>
    </div>
  );
}
