import React from "react";
import "./ToolsCollage.scss";

import toolsPhone from "assets/images/tools/welcome_white_2.png";
import toolsPhoneW from "assets/images/tools/welcome_white_2.webp";

import toolsAppStore from "assets/images/tools/loanpal_pro_app_store.jpg";
import toolsAppStoreW from "assets/images/tools/loanpal_pro_app_store.webp";

import toolsVideo from "assets/images/tools/tony.png";
import toolsVideoW from "assets/images/tools/tony.webp";
import toolsPhone2 from "assets/images/tools/phone2.png";
import toolsPhone2W from "assets/images/tools/phone2.webp";

import WrapperModalVideo from "./WrapperModalVideo";

import Picture from "./Picture";
import CardStore from "./CardStore";
export default function ToolsCollage() {
  const collageImages = [
    {
      image: toolsVideo,
      webp: toolsVideoW,
      videoId: "zDUAc0xi5B8",
      alt: "toolsVideo",
    },
    { image: toolsPhone, webp: toolsPhoneW, video: false, alt: "toolsPhone" },

    {
      image: toolsPhone2,
      webp: toolsPhone2W,
      video: false,
      alt: "toolsTimescreen",
    },
    {
      image: toolsAppStore,
      webp: toolsAppStore,
      video: false,
      alt: "toolsAppStore",
    },
  ];

  return (
    <div className="toolscollage">
      {collageImages.map((singleImage, index) => (
        <div key={`collage-tool-${index}`}>
          {singleImage?.videoId && (
            <div className={`toolscollage__picture--${index}`}>
              <WrapperModalVideo videoId={singleImage.videoId} showButton>
                <Picture
                  src={singleImage.image}
                  webp={singleImage.webp}
                  alt="We are mission-driven"
                />
              </WrapperModalVideo>
            </div>
          )}
          {!singleImage?.videoId && (
            <picture className={`toolscollage__picture--${index}`}>
              <source
                srcSet={singleImage.webp}
                alt={singleImage.alt}
                type="image/webp"
              />
              <source
                srcSet={singleImage.image}
                alt={singleImage.alt}
                type="image/jpeg"
              />
              <img src={singleImage.image} alt={singleImage.alt} />
            </picture>
          )}
        </div>
      ))}
      <CardStore appearance="toolscollage__picture--5" />
    </div>
  );
}
