import React, { useState, useEffect } from "react";
import "./HeaderSkewVideo.scss";

const transparentImage =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgAQAAAABbAUdZAAAAAnRSTlMAAHaTzTgAAAALSURBVHgBYxjkAAAAoAABVCqexwAAAABJRU5ErkJggg==";

export default function HeaderSkewVideo({
  background = false,
  backgroundColor = "orange",
  appearance = "normal",
  offsetImage = false,
  offsetValue = 0,
  offsetImageMobile = false,
  video = null,
  poster = "",
}) {
  const [backgroundSource, setBackgroundSource] = useState(null);

  const TagOffsetImage = offsetImage;

  useEffect(() => {
    if (poster) {
      const img = new Image();
      img.src = poster;
      img.onload = () => setBackgroundSource(poster);
    }
  }, [poster]);

  const regularBackground = backgroundSource
    ? { backgroundImage: `url(${backgroundSource || transparentImage})` }
    : {};

  const classNameHeader = `header-skew-video header-skew-video--${appearance} header-skew-video--${backgroundColor}`;

  const classNameHeaderOffset = `header-skew-video__content_offset ${
    offsetImageMobile ? "header-skew-video__content_offset--mobile" : ""
  } `;

  return (
    <div className={classNameHeader}>
      {offsetImage && (
        <div className={classNameHeaderOffset}>
          <div className="header-skew-video__content_offset_wrapper">
            <div
              className="header-skew-video__content_tag"
              style={{ position: "relative", top: `${offsetValue}px` }}
            >
              <TagOffsetImage />
            </div>
          </div>
        </div>
      )}

      <div
        className="header-skew-video__background"
        className={`header-skew-video__background ${
          poster && "header-skew-video__background--hasBackground"
        } ${backgroundSource && "header-skew-video__background--active"}
          `}
        style={regularBackground}
      >
        {video && (
          <video
            autoPlay
            loop
            muted
            playsInline
            poster={poster}
            className="header-skew-video__videobackground"
          >
            <source src={video} type="video/mp4" />
          </video>
        )}
      </div>
    </div>
  );
}
