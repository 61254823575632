

import React from 'react';
import './WrapperContent.scss'

export default function WrapperContent({children, appearance ="", ...props}) {
    return (
        <div className={`WrapperContent WrapperContent--${appearance} `}{...props}>
            {children}
        </div>
    )
}

