import React from "react";
import Button from "./Button";
import { navigate } from "gatsby";
import "./MediaSectionSlim.scss";

export default function MediaSectionSlim({
  title = "",
  description = "",
  descriptionMobile = "",
  children,
  modifier = ["normal"],
  button = false,
  buttonText = "Learn more",
  appearance = false,
}) {
  const modifierClasses = modifier
    .map((mod) => `media-section-slim--${mod}`)
    .join(" ");

  return (
    <div className={`media-section-slim ${modifierClasses}`}>
      <div className="media-section-slim__container" aos-data="fade-in">
        <div className="media-section-slim__container_children">{children}</div>
        <div className="media-section-slim__picture">{children[0]}</div>
        {appearance && (
          <div className="media-section-slim__picture media-section-slim__picture__second">
            {children[1]}
          </div>
        )}

        <div className="media-section-slim__background">
          <div className="media-section-slim__background_overlay" />
        </div>
      </div>
      <div
        className={`media-section-text__container ${
          appearance ? `media-section-text__container--${appearance}` : ""
        }`}
        aos-data="fade-in"
      >
        {title && (
          <h2
            className={`media-section-slim__title ${
              appearance ? `media-section-text__container--${appearance}` : ""
            }`}
          >
            {title}
          </h2>
        )}
        {description && (
          <p
            className={`media-section-slim__description`}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        )}

        {button && (
          <div className="media-section-slim__description">
            <Button
              appearance="slim-ghost"
              handleClick={() => navigate(button)}
              aria-label={buttonText}
              caret
            >
              {buttonText}
            </Button>
          </div>
        )}
      </div>
      {children && (
        <div
          className="media-section-text__container mobile"
          aos-data="fade-in"
        >
          <div className="media-section-slim__background_mobile" />
          <div
            className={`media-section-slim__picture ${
              appearance ? `media-section-slim__picture--${appearance}` : ""
            }`}
          >
            {appearance ? children[2] : children[1]}
          </div>
        </div>
      )}
    </div>
  );
}
